import Image from "next/image";

export default function SquaresDesign() {

  return (
    <div className="overflow-hidden !mt-[0rem]">
      <div className="squares squares-project origin-center !my-[0rem]">
      <Image
                      src="/svgs/Square.svg"
                      width={1737}
                      height={184}
                      alt="Square"
                      loading="lazy"
                      className="w-full"
                    />
      </div>
    </div>
  );
}
